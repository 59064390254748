import React from 'react'
import styles from './personDataInfo.module.css'
export const PersonDataInfo = ({ id = '' }) => {
  return (
    <section id={id} className={styles.container}>
      <div className={styles.inner}>
        <h1 className={styles.heading}>
          Podstawowe informacje o przetwarzaniu danych osobowych i dane
          kontaktowe{' '}
        </h1>
        <p className={styles.content}>
          <strong>
            Nutritionpro Sp. z o.o. z siedzibą: Rondo Daszyńskiego 2B,
            Mazowieckie, 00-843 no.07752971, NIP 5273035193, KRS 0001011605,
            email: info@nutritionpro.pl, phone: +48799 352 730
          </strong>
          (dalej jako "Dostawca") zgodnie z Rozporządzeniem Parlamentu
          Europejskiego i Rady (UE) 2016/679 w sprawie ochrony osób fizycznych w
          związku z przetwarzaniem danych osobowych i w sprawie swobodnego
          przepływu takich danych(dalej jako "RODO") dąży do ochrony danych
          osobowych i przetwarza tylko te dane osobowe, które służą do
          świadczenia wybranych przez Klienta usług przez czas niezbędny do tego
          celu.
        </p>
        <p className={styles.content}>
          Ponadto Usługodawca przetwarza dane osobowe przedstawicieli
          Klientów(kontrahentów), jak również potencjalnych Klientów
          zainteresowanych usługami Usługodawcy. Cele przetwarzania, jak również
          informacje o tytułach prawnych, prawnie uzasadnionych interesach oraz
          informacje o tym, czy dane osobowe są przetwarzane wyłącznie na
          podstawie wolnej i aktywnej zgody Klienta, są zawarte w podpisanej
          umowie lub w warunkach opublikowanych na stronie internetowej
          Usługodawcy. Dane osobowe są również przetwarzane w celu wypełnienia
          ustawowych obowiązków Usługodawcy wynikających z Ordynacji podatkowej
          lub innego przepisu prawnego, lub w innych celach realizujących
          uzasadniony interes Usługodawcy, a mianowicie:
        </p>
        <p>
          1. Ewidencja Klientów, z którymi przedstawiciele handlowi Usługodawcy
          skontaktowali się z ofertą zawarcia umowy, lub
        </p>
        <p>
          2. W celu zapobiegania oszustwom, kontroli zdefiniowanych procesów
          technologicznych,
        </p>
        <p>
          3. W celu rekrutacji nowych pracowników, którzy przekazują Usługodawcy
          swoje dane osobowe podczas rozmowy kwalifikacyjnej. Dane odrzuconych
          kandydatów są przechowywane przez okres nie dłuższy niż 6 miesięcy w
          celu skontaktowania się z odrzuconymi kandydatami z kolejną ofertą
          pracy / udziału w kolejnym lub powtórzonym postępowaniu
          kwalifikacyjnym w celu uzupełnienia wakatu, chyba że strony postanowią
          inaczej,
        </p>
        <p>
          4. W przypadku marketingu bezpośredniego oznacza to przetwarzanie
          danych osobowych dotychczasowych Klientów w celu zwrócenia się do nich
          z ofertą handlową lub badaniami. Usługodawca zawiadamia, że w
          przypadku zgłoszenia przez Klienta sprzeciwu wobec marketingu
          bezpośredniego dla danej usługi, Usługodawca nie będzie już
          przetwarzał danych osobowych tego Klienta w tym celu, a Usługodawca
          zaprzestanie zbliżania się do Klienta / wysyłania do niego ofert
          handlowych,
        </p>
        <p>5. Wykonanie i obrona roszczeń Usługodawcy</p>
        <p className={styles.content}>
          6. W związku z realizacją i dochodzeniem roszczeń prawnych w ramach
          roszczeń z tytułu rękojmi, sporów sądowych lub wykonywania orzeczeń.
        </p>
        <strong className={styles.contentItem}>
          Informacje zawarte w niniejszym dokumencie stanowią uzupełnienie
          informacji przekazanych Klientowi w zawartej umowie.
        </strong>
        <h2 className={styles.contentItem}>
          Dane identyfikacyjne i kontaktowe kontrolera
        </h2>
        <p className={styles.content}>
          Nutritionpro s.r.o. z siedzibą przy Inovační 122, 252 41
          Zlatníky-Hodkovice, nr rej. firmy 07752971, e-mail:
          info@nutritionpro.cz, tel: +420 777 510 839
        </p>
        <h2 className={styles.contentItem}>
          Contact details of the Data Protection Officer
        </h2>
        <p className={styles.content}>
          Nasz Inspektor Ochrony Danych jest do Państwa dyspozycji we wszystkich
          sprawach związanych z przetwarzaniem Państwa danych osobowych. Z
          inspektorem ochrony danych można skontaktować się pod adresem:
        </p>
        <p className={styles.content}>
          <strong>info@nutritionpro.pl</strong>
        </p>
        <h2>Bezpieczeństwo</h2>
        <p className={styles.content}>
          <strong>
            Wszystkie przetwarzane dane osobowe są zabezpieczone za pomocą
            odpowiednich środków organizacyjnych i technicznych.
          </strong>
        </p>

        <h2 className={styles.contentItem}>Odbiorcy danych osobowych</h2>
        <p className={styles.contentItem}>
          Odbiorcą danych osobowych jest osoba fizyczna lub prawna, organ
          rządowy, agencja lub inny podmiot, któremu ujawniane są dane osobowe,
          niezależnie od tego, czy jest to strona trzecia, czy nie. Organy
          rządowe, które mogą otrzymywać dane osobowe w ramach specjalnego
          dochodzenia zgodnie z prawem państwa członkowskiego, nie są uznawane
          za odbiorców; Przetwarzanie danych osobowych przez te organy rządowe
          musi być zgodne z obowiązującymi przepisami dotyczącymi ochrony danych
          w odniesieniu do konkretnych celów przetwarzania.
        </p>
        <p className={styles.contentItem}>
          Usługodawca przekazuje dane osobowe wyłącznie do następujących
          odbiorców, którzy uczestniczą w dostawie towarów / usług / realizacji
          płatności w ramach umowy, świadcząc usługi marketingowe.
        </p>
        <p className={styles.contentItem}>
          Administrator nie zamierza przekazywać danych osobowych do państwa
          trzeciego(państwa spoza UE) lub organizacji międzynarodowej.
        </p>
        <p className={styles.contentItem}>
          Podstawowy okres przechowywania danych osobowych jest zawsze określony
          w umowie pomiędzy klientem a Operatorem.
        </p>
        <p className={styles.contentItem}>
          Podstawowy okres przechowywania danych osobowych jest zawsze określony
          w umowie zawartej między Klientem a Usługodawcą.
        </p>
        <p className={styles.contentItem}>
          Dane osobowe potencjalnych Klientów, którzy podali swoje dane osobowe,
          ale nie zawarli umowy, są przechowywane w celach ewidencyjnych przez 1
          rok.
        </p>
        <p className={styles.content}>
          Dane osobowe Klientów są przechowywane w celach ewidencyjnych przez 10
          lat od momentu, kiedy umowa przestanie obowiązywać.
        </p>
        <h2 className={styles.contentItem}>Przechowywane dane</h2>

        <p className={styles.contentItem}>
          <strong>Dane kontaktowe</strong> :Imię i nazwisko, adres, numer
          telefonu, adres e-mail, numer rejestracyjny firmy, ponieważ ich
          przetwarzanie jest niezbędne do wykonania umowy, której stroną jest
          osoba, której dane dotyczą, lub do wykonania działań podjętych
          przedzawarciem umowy na żądanie osoby, której dane dotyczą, lub
          przetwarzanie jest niezbędne do celów wynikających z prawnie
          uzasadnionych interesów odpowiedniego administratora danych lub strony
          trzeciej, z wyjątkiem sytuacji, w których nadrzędny charakter wobec
          tych interesów mają interesy lub podstawowe prawa i wolności osoby,
          której dane dotyczą, wymagające ochrony danych osobowych. Celem jest
          zatem, aby Usługodawca wiedział, czego Klient wymaga od Usługodawcy i
          z jakiego powodu Klient skontaktował się z Usługodawcą.
        </p>
        <p className={styles.contentItem}>
          <strong>Dane dotyczące lokalizacji:</strong> Adres, kod pocztowy,
          miasto, państwo.
          <strong>
            Obowiązują te same powody i cele, które zostały opisane w sekcji
            Dane kontaktowe powyżej, ale w szczególności dla celów dostawy.
          </strong>
        </p>
        <p className={styles.contentItem}>
          {' '}
          <strong>Szczegóły dotyczące płatności:</strong> Sposób płatności.{' '}
          <strong>
            Obowiązują te same powody i cele, które zostały opisane w sekcji
            Dane kontaktowe powyżej, ale w szczególności, aby Dostawca mógł
            śledzić płatności i dopasować je do zrealizowanych zamówień lub
            rozliczyć roszczenia gwarancyjne.
          </strong>
        </p>
        <p className={styles.content}>
          {' '}
          <strong>Dane dotyczące dostawy:</strong> Nazwa, adres dostawy, numer
          telefonu, numer identyfikacyjny Klienta.{' '}
          <strong>
            Obowiązują te same powody i cele, które zostały opisane w sekcji
            Dane kontaktowe powyżej, ale w szczególności dostawa zamówień.Naszym
            kierowcom przekazywane są tylko dane niezbędne do dostawy.
          </strong>
        </p>
        <h2 className={styles.contentItem}>Inne przechowywane dane</h2>
        <p className={styles.contentItem}>
          Przechowywane są również wszelkie inne informacje i dane osobowe
          przekazane przez Klientów o innych osobach fizycznych, z którymi
          współpracują lub których dane są niezbędne do realizacji stosunku
          umownego. Klient jest zobowiązany do poinformowania tych osób o
          przekazaniu ich danych osobowych w podobny sposób.
        </p>
        <p className={styles.contentItem}>
          <strong>
            Dostawca gromadzi również dane osobowe uzyskane podczas rozmów
            telefonicznych lub na portalach społecznościowych (Facebook, Twitter
            itp.). Rozmowy telefoniczne mogą być monitorowane i nagrywane w celu
            zapewnienia jakości i należytego funkcjonowania naszych usług.
            Zapisy mogą być przechowywane przez okres do sześciu miesięcy na
            potrzeby wyłącznie wewnętrzne. Obowiązują te same powody i cele,
            które zostały opisane w sekcji Dane kontaktowe powyżej.
          </strong>
        </p>
        <p className={styles.contentItem}>
          <strong>Szczególne powody przetwarzania danych osobowych</strong>
        </p>
        <p className={styles.contentItem}>
          <strong>Kierowcy / przewoźnicy</strong> Dane niezbędne do dostarczenia
          zamówienia są przekazywane tym osobom, czy to w ramach stosunku pracy
          lub podobnego stosunku, czy też jest to osoba trzecia odpowiedzialna
          za dostarczenie zamówienia. Dane dotyczące dostawy stanowią kategorię
          danych osobowych zgodnie z sekcją 6 lit. bGDPR.
        </p>
        <p className={styles.contentItem}>
          <strong>Marketing bezpośredni:</strong> Podając swój adres e-mail
          wyrażają Państwo zgodę na otrzymywanie naszych ofert handlowych drogą
          elektroniczną. Przetwarzanie danych osobowych Klientów (numery
          telefonów, adresy e-mail itp.) w celach marketingu bezpośredniego
          (przy wysyłaniu ofert handlowych) jest przetwarzaniem prowadzonym w
          uzasadnionym interesie na podstawie art. 6 ust. 1 lit. f GDPR.
          Niniejszym informujemy naszych Klientów o przysługującym im prawie do
          wniesienia sprzeciwu wobec przetwarzania danych osobowych na potrzeby
          marketingu bezpośredniego na podstawie art. 21 ust. 2 GDPR. Jeśli
          Klient sprzeciwi się przetwarzaniu, jego dane osobowe nie mogą być już
          przetwarzane w tych celach (zasada opt-out); patrz Wycofanie zgody
          poniżej. Dane kontaktowe i dane o lokalizacji stanowią kategorię
          danych osobowych. Jeśli Klient nie zgadza się od początku na wysyłanie
          informacji handlowych, e-maili itp. powyżej w celach marketingu
          bezpośredniego, może powiedzieć o tym wymogu Usługodawcy w swoim
          pierwszym zamówieniu lub wysłać to zawiadomienie na poniższy adres
          e-mail; patrz Wycofanie zgody poniżej.
        </p>
        <p className={styles.contentItem}>
          <strong>Targetowanie.</strong> Targetowanie oznacza dostosowanie, tj.
          wyłączanie, aktywację i modulację banerów reklamowych na stronach
          internetowych dla określonych grup docelowych w celu dostarczenia
          użytkownikowi sieci / potencjalnemu Klientowi najskuteczniejszego
          przekazu w sposób indywidualny.
        </p>
        <p className={styles.contentItem}>
          <strong>Retargetowanie.</strong> Retargetowanie oznacza wykorzystanie
          plików cookies w przypadku ponownych odwiedzin na stronie internetowej
          Usługodawcy. Funkcja ta może być również wyłączona poprzez
          dezaktywację dodatków w przeglądarce internetowej lub ograniczona lub
          wyłączona poprzez usunięcie plików cookies. Dane kontaktowe stanowią
          kategorię danych osobowych.
        </p>
        <p className={styles.contentItem}>
          <strong>Pliki cookies.</strong> Cookie to krótki plik tekstowy
          zapisywany w przeglądarce podczas odwiedzania strony internetowej.
          Pozwala on stronie internetowej na zapisanie informacji o Państwa
          wizycie. W ten sposób kolejna wizyta może być łatwiejsza, a strona
          bardziej przydatna dla Ciebie. Większość przeglądarek pozwala na
          kontrolowanie ustawień i wykorzystania plików cookie podczas
          przeglądania oraz na usuwanie plików cookie i danych przeglądania.
          Ponadto przeglądarka może oferować zarządzanie plikami cookie na
          poziomie witryny. Możesz sprzeciwić się używaniu przez nas plików
          cookie, wysyłając wiadomość na poniższy adres e-mail; patrz Wycofanie
          zgody. Rozpatrzymy Twoje żądanie bez zbędnej zwłoki, jednak Dostawca
          powinien mieć świadomość, że sprzeciw ten będzie skuteczny tylko w
          przeglądarce internetowej, w której został utworzony.
        </p>
        <p className={styles.content}>
          Możesz
          <strong> wycofać swoją zgodę,</strong> informując nas o wycofaniu zgody
          na wykorzystanie danych osobowych na info@nutritionpro.cz. Wszelkie
          SPRZECIWY wobec przetwarzania danych osobowych prosimy kierować
          również na ten adres.
        </p>
        <h2 className={styles.contentItem}>Twoje prawa</h2>
        <p className={styles.content}>
          Na warunkach określonych w RODO masz również prawo wniesienia skargi
          do Urzędu Ochrony Danych Osobowych, jeżeli uznasz, że Twoje prawo do
          ochrony danych osobowych zostało naruszone.Ponadto masz prawo złożyć
          skargę do Urzędu Ochrony Danych Osobowych, jeśli uważasz, że Twoje
          prawo do ochrony danych osobowych zostało naruszone.
        </p>
        <h2 className={styles.contentItem}>Postanowienia końcowe</h2>
        <p className={styles.contentItem}>
          1. Wysyłając to zamówienie za pośrednictwem formularza zamówienia
          online, potwierdzasz, że zapoznałeś się z Polityką prywatności i
          akceptujesz ją w całości.
        </p>
        <p className={styles.contentItem}>
          2. Zaznaczając pole zgody w formularzu online, zgadzasz się z
          niniejszą Polityką prywatności. Zaznaczając pole zgody potwierdzasz,
          że zapoznałeś się z Polityką Prywatności i akceptujesz ją w całości.
        </p>
        <p className={styles.contentItem}>
          3. Administrator jest uprawniony do zmiany niniejszej Polityki.
          Administrator opublikuje nową wersję Polityki Prywatności na stronie
          internetowej Administratora lub prześle Ci nową wersję Polityki na
          podany Administratorowi adres e-mail.
        </p>
        <p className={styles.contentItem}>
          4. Zastrzegamy sobie prawo do zmiany niniejszego Oświadczenia o
          ochronie prywatności zgodnie z przepisami prawa. Będziemy Cię
          informować o wszelkich istotnych zmianach, np. zmianie celu lub nowych
          celach przetwarzania.
        </p>
      </div>
    </section>
  )
}
